import { initializeApp } from "firebase/app";
//import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import {getStorage, connectStorageEmulator} from "firebase/storage"
import {getFunctions, connectFunctionsEmulator} from "firebase/functions"

const firebaseConfig = {
  apiKey: "AIzaSyCNtD3j_6rAesDkhQkLXVO66V_YqbiJKHg",
  authDomain: "tiu-video-upload.firebaseapp.com",
  projectId: "tiu-video-upload",
  storageBucket: "tiu-video-upload.appspot.com",
  messagingSenderId: "627237178208",
  appId: "1:627237178208:web:aa0d0849a966dcc3896c05"
};

// Initialize Firebase
let firebase = initializeApp(firebaseConfig);

const db = getStorage(firebase);
const func = getFunctions(firebase);

if (location.hostname === 'localhost') {
  connectStorageEmulator(db, "127.0.0.1", 8081);
  connectFunctionsEmulator(func, "localhost", '5001')
}

export {firebase, db, func}