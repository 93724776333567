<template>
  <div id="modal">
    <form
      v-if="showModal === 'form'"
      id="video-form"
      @submit="
        (e) => {
          e.preventDefault();
        }
      "
    >
      <input
        @change="(e) => acceptVideo(e)"
        id="video"
        placeholder="Video auswählen oder  hier reinziehen …"
        type="file"
        accept="video/mp4,video/x-m4v,video/*"
      />
      <div class="div-input">
        <input
          @keyup.enter="
            (e) => {
              e.preventDefault(), testForData;
            }
          "
          v-model="form.name"
          type="text"
          id="name"
          placeholder="Vorname"
        />
        <input
          v-model="form.lastName"
          type="text"
          id="last-name"
          placeholder="Nachname"
        />
      </div>
      <input
        v-model="form.password"
        id="password"
        placeholder="Kennwort"
        type="password"
      />
      <div class="div-input">
        <input v-model="form.userr" id="nutzerbedingungen" type="checkbox" />
        <label for="nutzerbedingungen"
          >Ich stimme hiermit den
          <span id="nr-button" @click="showModal = 'userr'"
            >Nutzungsrechten</span
          >
          zu.</label
        >
      </div>

      <button id="upload-button" @click="testForData">Hochladen</button>
    </form>
    <div v-if="showModal === 'userr'" id="modal-nuzerbedingungen">
      <p class="headline">
        Nutzungsrechte
        <button id="close" @click="showModal = 'form'">&#10005;</button>
      </p>
      <div id="userr-text">
        <p>
          Durch das Bereitstellen des Videos erklären Sie Ihre ausdrückliche
          Zustimmung zur uneingeschränkten und unwiderruflichen Übertragung
          sämtlicher Rechte an Bild und Ton an Comline. Diese Rechte umfassen
          die uneingeschränkte Nutzung, Bearbeitung, Veröffentlichung,
          Verbreitung und Nutzung der Videos, einschließlich aller damit
          verbundenen Rechte und Lizenzen. <br /><br />
          Die Videos werden zu verschiedenen Zwecken genutzt, darunter Bildung
          und kommerzielle Verwendung, wie beispielsweise bei Vorträgen, auf der
          Webseite von Comline und Partnern und auf verschiedenen Social
          Media-Plattformen. <br /><br />
          Comline übernimmt keinerlei Verantwortung für den Inhalt der von den
          Lehrerinnen und Lehrern bereitgestellten Selfie-Videos. Alle gemachten
          Aussagen und gezeigten Inhalte spiegeln ausschließlich die Meinung und
          Erfahrungen der betreffenden Lehrkraft wider. <br /><br />
          Für weitere Informationen oder bei Fragen kontaktieren Sie uns bitte
          unter
          <a href="mailto:marketing@comline-shop.de"
            >marketing@comline-shop.de</a
          >.
        </p>
      </div>
    </div>
    <div v-if="showModal === 'success'" id="modal-success">
      <p class="headline">
        <button id="close" @click="showModal = 'form'">&#10005;</button>
      </p>
      <div id="success-text">
        <p>Ihr Video wurde erfolgreich hochgeladen.</p>
        <button id="ok" @click="showModal = 'form'">Ok</button>
      </div>
    </div>
  </div>
</template>

<script>
//import { func } from "./../plugins/firebase";
import { httpsCallable, getFunctions } from "firebase/functions";
import { ref, uploadBytes } from "firebase/storage";
import { db } from "../plugins/firebase";

export default {
  name: "VideoForm",
  data() {
    return {
      showModal: "form",
      form: {
        video: null,
        name: "",
        lastName: "",
        password: "",
        userr: false,
      },
      incomplete: [],
      clickListener: null,
    };
  },
  methods: {
    async acceptVideo(e) {
      let file = e.target.files[0];
      this.form.video = file;
    },
    renameVideo() {
      let file = this.form.video;
      let typeEnd = file.name.split(".")[file.name.split(".").length - 1];

      var blob = file.slice(0, file.size, file.type);
      let newFile = new File(
        [blob],
        this.form.name + this.form.lastName + Date.now() + "." + typeEnd,
        { type: file.type }
      );
      return newFile;
    },
    testForData() {
      //tests the form for completeness
      //notes the fields which have been left empty
      let form = this.form;
      if (form.video === null) this.incomplete.push("video");
      if (form.name === "") this.incomplete.push("name");
      if (form.lastName === "") this.incomplete.push("last-name");
      if (form.password === "") this.incomplete.push("password");
      if (form.userr === false) this.incomplete.push("nutzerbedingungen");
      if (this.incomplete.length > 0) this.paintItRed();
      else this.saveData();
    },
    paintItRed() {
      //marks fields, if they're empty or the password is wrong
      this.incomplete.forEach((element) => {
        if (element === "nutzerbedingungen")
          document.getElementById(element).nextElementSibling.style.color =
            "red";
        document.getElementById(element).style.borderColor = "red";
      });
      let inputs = document.getElementsByTagName("input");
      for (let i = 0; i < inputs.length; i++) {
        //prepares the process to take back the marking
        inputs[i].addEventListener("click", this.paintItBack);
      }
    },
    paintItBack() {
      //reverts color of the fields back to standart color
      //empties the noted field, that have been empty
      this.incomplete.forEach((element) => {
        if (element === "nutzerbedingungen")
          document.getElementById(element).nextElementSibling.style.color =
            "#2c3e50";
        document.getElementById(element).style.borderColor = "#ccc";
      });
      this.incomplete = [];
    },
    async saveData() {
      //prepare video name
      const video = this.renameVideo();
      //checks the password
      const checkPassword = httpsCallable(getFunctions(), "checkpassword");
      checkPassword(this.form.password)
        .then((res) => {
          //if password correct, upload video to storage
          if (res.data) {
            //url eference
            const videoRef = ref(db, video.name);
            //upload to storage
            uploadBytes(videoRef, video).then(() => {
              this.showModal = "success";
            });
          } else {
            //if password is false, mark password input red
            this.incomplete.push("password");
            this.paintItRed();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
};
</script>

<style scoped>
#modal {
  background-color: white;
  padding: 30px;
  border-radius: 25px;
  max-width: 800px;
  min-width: 351px;
  width: 60vw;
  height: 243px;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
}
#video-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
  width: 90%;
  max-width: 600px;
  position: relative;
}
#durchsuchen {
  width: fit-content;
  padding: 9px 17px;
  color: white;
  background-color: #868686;
  border: none;
  border-radius: 4px 0 0 4px;
  position: absolute;
  top: 0px;
  left: -11px;
}
input[type="text"],
input[type="file"],
input[type="password"] {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  margin-bottom: 25px;
  width: 100%;
}
.div-input {
  width: calc(100% + 22px);
}
.div-input:first-of-type {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
#name,
#last-name {
  width: calc(50% - 35px) !important;
  display: inline-block;
}
#nr-button {
  color: #2292e1;
}
#nr-button:hover {
  color: #78bded;
  cursor: pointer;
}
#upload-button,
#ok {
  width: fit-content;
  padding: 7.5px 20px;
  color: white;
  background-color: #868686;
  border: none;
  border-radius: 4px;
  margin-top: 25px;
}
#upload-button:hover,
#durchsuchen:hover,
#ok:hover {
  background-color: #adadad;
  cursor: pointer;
}
p {
  text-align: start;
}
.headline {
  width: 100%;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
}
#close {
  background-color: white;
  border: none;
  color: #868686;
  float: right;
}
#userr-text {
  overflow-y: scroll;
  height: 214px;
  padding-right: 15px;
}
#success-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 70px;
}
</style>