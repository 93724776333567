<template>
  <div id="header">
    <img src="../assets/logo-1-1.png" alt="Comline logo in black" />
  </div>
  <div id="image-wall"><div><p>Upload Portal <span>- Lehrer Videos</span></p></div></div>
</template>

<script>
export default {
  name: "UploadHeader",
};
</script>

<style scoped>
#header {
  height: 65px;
  display: flex;
  align-items: center;
  padding: 0 35px 0 35px;
  background-color: white;
}
#header img {
  height: 80%;
}
#image-wall {
  background-image: url("../assets/tiu.jpeg");
  width: 100%;
  height: 300px;
  background-position-y: -145px;  
  background-repeat: no-repeat;
  background-size: cover;
}
#image-wall div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000040;
}
#image-wall div p {
  text-align: center;
  padding: 0 150px;
      font-size: 64px;
  color: white;
  font-family: Helvetica, Arial, sans-serif;
}
</style>