<template>
  <div id="bucket">
    <upload-header />
    <video-form />
    <upload-footer />
  </div>
</template>

<script>
import VideoForm from "./components/VideoForm.vue";
import UploadHeader from "./components/UploadHeader.vue";
import UploadFooter from "./components/UploadFooter.vue";

export default {
  name: "App",
  components: {
    VideoForm,
    UploadHeader,
    UploadFooter,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  height: 100vh;
}
body {
  margin: 0;
  height: 100%;
  background-color: #f2f2f2;
}
#bucket {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
</style>
