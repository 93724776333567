<template>
  <div class="footer-bottom">
    <div class="container">
      <div class="footer-bottom-content">
        <div class="footer-content-left">
          <div class="copyrights site-info">
            <p>
              <img
                src="https://www.tablets-im-unterricht.de/wp-content/uploads/2023/09/logo.png"
                style="width: 150px; margin-top: 15px; margin-bottom: 10px"
              /><br />

              <img
                src="https://www.tablets-im-unterricht.de/wp-content/uploads/2023/09/logo-1.png"
                style="width: 150px; margin-bottom: 15px; margin-top: 10x"
              /><br />

              Lise-Meitner-Straße 16, 24941 Flensburg, Deutschland<br />
              © ComLine GmbH Value Added Distribution
            </p>
            <a href="https://www.tablets-im-unterricht.de/impressum"
              >Impressum</a
            >
            |
            <a href="https://www.tablets-im-unterricht.de/datenschutz"
              >Datenschutz</a
            ><br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadFooter",
};
</script>

<style scoped>
.footer-bottom {
  width: 100%;
  background-color: white;
  padding: 5px 0 35px 0;
  position: relative;
  bottom: 0;
  margin-top: auto;
}
.site-footer {
  text-align: center;
  font-size: 16px;
}
.site-footer.footer-bottom-horizontal .footer-bottom-content {
  display: table;
  table-layout: auto;
  width: 100%;
  direction: rtl;
}
.site-footer .footer-bottom-content {
  padding-top: 30px;
  padding-bottom: 30px;
}
bottom-horizontal .footer-bottom-content .footer-content-left, .site-footer.footer-bottom-horizontal .footer-bottom-content .footer-content-right {
  display: table-cell;
  vertical-align: middle;
  direction: ltr;
}
.site-footer .footer-bottom-content .copyrights p {
  margin-bottom: 0;
}
.site-footer .footer-bottom-content a, .site-footer .footer-bottom-content p {
  line-height: 1.5;
  font-size: 13px;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: #7e7e7e;
}
</style>